import React, { memo, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import Image from '@/components/Image/Image';
import InstagramIcon from '@/components/svgs/instagram-color.svg';
import routes from '@/data/routes';
import { setIsMenuOpen, useAppDispatch, useAppSelector } from '@/redux';
import styles from './Header.module.scss';
import { useRouter } from 'next/router';
import { gsap } from 'gsap';
import lockBodyScroll from '@/services/lock-body-scroll';
// import scroll from '@/services/scroll';

export type Props = {
  className?: string;
};

function Header({ className }: Props) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [actualRoute, setActualRoute] = useState(router.route);
  const isMenuOpen = useAppSelector((state) => state.isMenuOpen);
  const navLinks: (HTMLAnchorElement | null)[] = [];
  const headerRef = useRef<HTMLElement | null>(null);
  const navRef = useRef<HTMLElement | null>(null);
  const headerBrand = useRef<HTMLDivElement | null>(null);
  const TimeLineIn = useRef<GSAPTimeline | null>(null);
  const TimeLineOut = useRef<GSAPTimeline | null>(null);

  useEffect(() => {
    gsap.set(navRef.current, {
      autoAlpha: 0,
      x: '100%'
    });

    // const handleScroll = () => {
    //   if (window?.pageYOffset > 350) {
    //     headerBrand.current?.classList.add('logo-hidden');
    //   } else {
    //     headerBrand.current?.classList.remove('logo-hidden');
    //   }
    // };

    // scroll.listen(handleScroll);

    // return () => {
    //   scroll.dismiss(handleScroll);
    // };
  }, []);

  useEffect(() => {
    if (actualRoute !== router.route && isMenuOpen) {
      dispatch(setIsMenuOpen(false));
    }
    setActualRoute(router.route);
  }, [router.route, isMenuOpen, actualRoute]);

  useEffect(() => {
    if (actualRoute !== router.route && isMenuOpen) {
      dispatch(setIsMenuOpen(false));
    }
    setActualRoute(router.route);
  }, [router.route, isMenuOpen, actualRoute]);

  useEffect(() => {
    if (isMenuOpen) {
      lockBodyScroll.lock();
      if (TimeLineOut.current && TimeLineOut.current.isActive()) {
        TimeLineOut.current.kill();
      }
      headerRef.current?.classList.add('Header--mobileOpen');

      TimeLineIn.current = gsap
        .timeline({ defaults: { duration: 0.8, ease: 'expo' } })
        .set(navLinks, {
          y: '20%',
          rotate: 15
        })
        .set(navRef.current, {
          autoAlpha: 0,
          x: '100%'
        })
        .to(navRef.current, {
          autoAlpha: 1,
          x: '0'
        })
        .to(
          navLinks,
          {
            y: '0%',
            rotate: 0,
            stagger: 0.03
          },
          0
        );
    } else {
      lockBodyScroll.unlock();

      if (TimeLineIn.current && TimeLineIn.current.isActive()) {
        TimeLineIn.current.kill();
      }

      TimeLineOut.current = gsap
        .timeline({
          defaults: { duration: 0.5, ease: 'power2' }
        })
        .to(navRef.current, {
          autoAlpha: 0,
          x: '100%'
        })
        .to(
          navLinks,
          {
            y: '-100%',
            stagger: 0.03
          },
          0
        );

      headerRef.current?.classList.remove('Header--mobileOpen');
    }
  }, [isMenuOpen]);

  return (
    <header className={classnames(styles.Header, className)} ref={headerRef}>
      <div className={styles.wrapper}>
        <div className={styles.brand} ref={headerBrand}>
          <h1>
            <span>Maratone Benessere Donna</span>
            <Link href="/" prefetch={false}>
              <a>
                <Image src="logo.png" alt="Maratone Benessere Donna" />
              </a>
            </Link>
          </h1>
        </div>
        <div
          className={styles.burgerWrapper}
          onClick={() => {
            dispatch(setIsMenuOpen(!isMenuOpen));
          }}
        >
          <div className={classnames(styles.burger, isMenuOpen && styles.burgerClose, 'burger')}>
            <div className={styles.bar}></div>
            <div className={styles.bar}></div>
            <div className={styles.bar}></div>
          </div>
        </div>
      </div>
      <nav className={classnames(styles.nav)} ref={navRef}>
        <ul className={styles.routes}>
          {Object.values(routes).map(({ path, title }) => {
            return (
              <li key={path} className={classnames(path === router.route ? styles.navLinkActive : null)}>
                <Link href={path} prefetch={false}>
                  <a ref={(el) => navLinks.push(el)}>{title}</a>
                </Link>
              </li>
            );
          })}
          <li>
            <a
              ref={(el) => navLinks.push(el)}
              href="https://www.instagram.com/nadinebenesseredonna"
              className={styles.instaBtn}
            >
              <InstagramIcon />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default memo(Header);
